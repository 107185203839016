import { Container } from "@chakra-ui/react"
import NormalHeading from "@components/headings/NormalHeading"
import StylizedHeading from "@components/headings/StylizedHeading"
import HeaderNavBasic from "@components/nav/HeaderNavBasic"

export default function Custom404() {
  return (
    <>
      <HeaderNavBasic />
      <Container
        maxW={"container.xl"}
        px={{ base: 10, xl: 0 }}
      >
        <NormalHeading>{"Page Not Found"}</NormalHeading>
        <StylizedHeading>{"404"}</StylizedHeading>
      </Container>
    </>
  )
}
