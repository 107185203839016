import { Box, Container, Flex, HStack, Icon, Link } from "@chakra-ui/react"
import LanguageSelect from "@components/nav/LanguageSelect"
import { HeaderNavProps } from "@definitions/types"
import SvgLogo from "@public/svg/logo.svg"
import NextLink from "next/link"

const HeaderNavBasic = ({ ...props }: HeaderNavProps) => {
  return (
    <Box
      position={"relative"}
      backgroundColor={"brand.primary"}
      shadow={"md"}
      py={6}
      zIndex={1}
      {...props}
    >
      <Container
        maxW={"container.xl"}
        position={"relative"}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Link
            as={NextLink}
            href={"/"}
            _focus={{ boxShadow: "none" }}
            aria-label={`Digital eMenu`}
            zIndex={1}
          >
            <Icon
              as={SvgLogo}
              color={"white"}
              width={{ base: 40, md: 56 }}
              height={24}
            />
          </Link>
          <Box display={{ base: "none", md: "block" }}>
            <HStack spacing={{ base: 4, xl: 8 }}>
              <LanguageSelect fontWeight={500} />
            </HStack>
          </Box>
          <Flex
            justifyContent={"flex-end"}
            alignItems={"center"}
            display={{ base: "flex", md: "none" }}
          >
            <LanguageSelect
              backgroundColor={"brand.dark"}
              shadow={"sm"}
              me={4}
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default HeaderNavBasic
